import React, { useState, useEffect } from "react"
import "../layout.css"
import { useLocation } from "@reach/router"
import { useStateIfMounted } from "use-state-if-mounted"
import { NavigationBar } from "../NavigationBar/parent"
import { MainPageJson, PathJson, AdvanceFAQJson } from "../location"
import Footer from "../FooterComponents/Footer"
import { SgFaqHeader } from "../SingaporeFaq/SgFaqHeader"
import Seo from "../SEOComponents/Seo"
import { AdvanceFAQBody } from "./AdvanceFaqBody"
import { LayoutTextPage } from "../LayoutTextPage/parent"
import axios from "axios"
import { fetch } from "../../tools/api"

function AdvanceFAQ() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const AdvanceSgContent = AdvanceFAQJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const { Title, Desc } = AdvanceSgContent.Header || {}
//   const [data, setData] = useState({})
//   const [loading, setLoading] = useState(false)

//   const FetchData = async () => {
//     try {
//       setLoading(true)
//       const { data } = await axios.get(
//         `https://strapi.transfez.com/swift-codes?swift_code_eq=${props.code}`
//       )
//       setData(data)
//     } catch (error) {
//       console.log(error)
//     } finally {
//       setLoading(false)
//     }
//   }

//  useEffect(() => {
//     FetchData()
//   }, [])
//   console.log(data)

// const { data, loading } = fetch({ url: "/api/faq/v2_all" })

  return (
    <div className="maxwidth">
      <Seo title="faq" />
      <NavigationBar />
      <LayoutTextPage title={Title} desc={Desc}>
        <AdvanceFAQBody
          isEnglish={isEnglish}
          props={AdvanceSgContent}
          propsLink={PathContent}
        />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default AdvanceFAQ
